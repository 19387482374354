.sitewide-footer-formatter {
  &__trustmark {
    display: none;
    &-wrapper {
      &.trustmark-logo-wrapper {
        clear: both;
        display: block;
        margin: 0;
        padding: 20px 0;
        text-align: center;
        @include breakpoint($landscape-up) {
          @include swap_direction(margin, 25px auto 0 250px);
          order: 4;
          padding: 0;
          width: auto;
        }
      }
      img {
        margin: 0;
        width: 150px;
      }
    }
  }
  .footer-country-link {
    @include breakpoint($landscape-up) {
      width: auto;
    }
  }
  &__terms-links {
    &.footer-links {
      margin: 0;
    }
  }
  .footer-language-toggle {
    .active {
      text-decoration: underline;
    }
  }
}
